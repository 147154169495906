var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login" }, [
    _c("div", { staticClass: "main" }, [
      _vm.loginType
        ? _c("div", { staticClass: "login-type" }, [
            _vm._m(0),
            _c("p", [_vm._v("首次登录后自动注册")]),
            _c("div", { staticClass: "code" }, [
              _vm.qrcodeUrl
                ? _c("img", {
                    staticClass: "qrcode",
                    attrs: { src: _vm.qrcodeUrl, alt: "" },
                    on: { click: _vm.refresh }
                  })
                : _vm._e()
            ])
          ])
        : _c(
            "div",
            { staticClass: "login-type" },
            [
              _vm._m(1),
              _c(
                "el-form",
                { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入手机号码" },
                        model: {
                          value: _vm.form.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "code" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入短信验证码" },
                                model: {
                                  value: _vm.form.code,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "code", $$v)
                                  },
                                  expression: "form.code"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _vm.isSend
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "sendCode",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.sendCode }
                                    },
                                    [_vm._v("发送验证码")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      staticClass: "sendCode",
                                      attrs: { type: "primary", disabled: "" }
                                    },
                                    [_vm._v(_vm._s(this.countdown + "s"))]
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "toPhoneLogin",
                          attrs: { type: "primary", size: "medium" },
                          on: { click: _vm.toPhoneLogin }
                        },
                        [_vm._v("登录")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _vm._m(2),
      _c("ul", { staticClass: "login-way" }, [
        _vm.loginType
          ? _c("li", { on: { click: _vm.toLogin } }, [
              _c("i", { staticClass: "el-icon-mobile-phone" }),
              _c("p", [_vm._v("手机登录")])
            ])
          : _c(
              "li",
              {
                on: {
                  click: function($event) {
                    _vm.loginType = true
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "wx-icon",
                  attrs: {
                    src:
                      "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=2063486912,4180716630&fm=26&gp=0.jpg",
                    alt: ""
                  }
                }),
                _c("p", [_vm._v("微信登录")])
              ]
            ),
        _c("li", [
          _c("i", { on: { click: _vm.qqLogin } }, [
            _c("img", {
              staticClass: "qq-icon",
              attrs: {
                src:
                  "http://cube-test-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210108/adbd0f57efa1416dafebbda9fadaf705.gif",
                alt: ""
              }
            })
          ]),
          _c("p", [_vm._v("QQ登录")])
        ])
      ]),
      _c("p", { staticClass: "agreement" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.isAgreen,
              expression: "isAgreen"
            }
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.isAgreen)
              ? _vm._i(_vm.isAgreen, null) > -1
              : _vm.isAgreen
          },
          on: {
            change: function($event) {
              var $$a = _vm.isAgreen,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.isAgreen = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.isAgreen = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.isAgreen = $$c
              }
            }
          }
        }),
        _vm._v(" 我阅读并同意 《无限立方用户协议》 ")
      ]),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isAgreen,
              expression: "!isAgreen"
            }
          ],
          staticClass: "tips"
        },
        [_vm._v("请阅读并同意《无限立方用户协议》")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "title title-wx" }, [
      _c("img", {
        staticClass: "wx-icon",
        attrs: {
          src:
            "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=2063486912,4180716630&fm=26&gp=0.jpg",
          alt: ""
        }
      }),
      _vm._v(" 微信扫码登录注册 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-text" }, [
      _c("h2", { staticClass: "title" }, [_vm._v(" 手机验证码登录 ")]),
      _c("p", [_vm._v("首次登录后自动注册")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "other" }, [
      _c("li"),
      _c("li", { staticClass: "other-text" }, [_vm._v("其他方式登录")]),
      _c("li")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }